<i18n>
	{
		"en": {
			"register": "Register as a Nimbuzz user.",
			"alreadyHaveAccount": "Already have account?",
			"login": "Login",
			"enterName": "Enter your name",
			"enterNumber": "Enter mobile number",
			"enterPass": "Enter password",
			"confirmPass": "Enter confirm password",
			"register": "Register",
			"activationCodeSentTo": "Activation code sent at",
			"enterVerificationCode": "Please enter verification code.",
			"requestAgain": "Request Code Again",
			"ok": "Ok",
			"back": "Back"
		},
		"np": {
				"register": "निम्बज प्रयोगकर्ताको रूपमा दर्ता गर्नुहोस्।",
				"alreadyHaveAccount": "पहिले नै खाता छ?",
				"login": "लगइन",
				"enterName": "तपाईको नाम राख्नुहोस्",
				"enterNumber": "मोबाइल नम्बर राख्नुहोस्",
				"enterPass": "पासवर्ड राख्नुहोस्",
				"confirmPass": "निश्चित पासवर्ड राख्नुहोस्",
				"register": "दर्ता गर्नुहोस्",
				"activationCodeSentTo": "सक्रियकरण कोड यहाँ पठाइयो",
				"enterVerificationCode": "कृपया प्रमाणीकरण कोड राख्नुहोस्।",
				"requestAgain": "कोड पुन: अनुरोध गर्नुहोस्",
				"ok": "हुन्छ",
				"back": "पछाडि"
		}
	}
</i18n>

<template lang="pug">
	.hero-banner
		.hero-banner-content
			.container
				.login-modal.login-box
					.modal-header
						h6.font-weight-bold {{ $t("register") }}
						small {{$t("alreadyHaveAccount")}}
							router-link.text-danger.ml-2(to='/login') {{$t("login")}}
					.modal-body
						.show-message(v-if='message') {{message}}
						.row
							.col-sm-12.col-lg-6
								transition(name='fade' mode='out-in')
									form(@submit.prevent='register' v-if='!verificationSent')
										.form-group
											input.form-control(type='text' v-model='user.fullName' name='fullName'
											:placeholder='$t("enterName")' autofocus required minlength="4")
										.form-group
											.input-group
												.input-group-prepend
													.input-group-text +977
												input.form-control(type='tel' pattern='[0-9]+' v-model='user.phoneNumber'
													:placeholder='$t("enterNumber")' name="username" autofocus required
													minlength='10' maxlength='10')
										.form-group
											input.form-control(type='password' v-model='user.password' :placeholder="$t('enterPass')"
												minlength='6' maxlength='24' required)
										.form-group
											input.form-control(type='password' v-model='user.confirmPassword' :placeholder="$t('confirmPass')"
												minlength='6' maxlength='24' required)
										.text-center.mt-4
											button.btn.btn-danger.btn-block(:disabled='loading')
												.text-center
													.label(v-if='!loading') {{ $t('register') }}
													b-spinner(small v-else)
									div(v-else)
										form(@submit.prevent='submitVerificationCode')
											.form-group
												.text-muted {{ $t("activationCodeSentTo") }}&nbsp; {{user.phoneNumber}}
											.form-group
												label {{ $t("enterVerificationCode") }}
												input.form-control(v-model='verificationCode' type='tel'
													maxlength='6' pattern='[0-9]+' placeholder='6 digit code' required)
												.div.mt-2
													u.text-danger.cursor-pointer(@click='register') {{ $t("requestAgain") }}
												.row.mt-2
													.col-md-6
														button.btn.btn-danger.btn-block(type='submit' :disabled='loading')
															.text-center
																.label(v-if='!loading') {{ $t("ok") }}
																b-spinner(small v-else)
													.col-md-6
														button.btn.btn-secondary.btn-block(type='button' @click='verificationSent = false' :disabled='loading') {{ $t("back") }}
		.hero-silhouette
		
</template>

<script>
import { REMOTE_SERVER_1, prepareHeaders } from "@/helpers/general";
import axios from "@/axios";
export default {
	name: "login",
	data() {
		return {
			user: {
				fullName: "",
				phoneNumber: "",
				password: "",
				confirmPassword: "",
				countrycode: "+977",
				isWeb: 1,
			},
			error: null,
			verificationSent: false,
			verificationCode: "",
			message: "",
			loading: false,
		};
	},
	methods: {
		async register() {
			if (this.user.password != this.user.confirmPassword) {
				this.message = "Passwords don't match.";
				return;
			}
			this.loading = true;
			let response = await axios.post(REMOTE_SERVER_1 + "user/signup/sendSms", this.user);
			if (response.data && response.data.code == 1) {
				this.verificationSent = true;
			} else if (response.data.message) {
				this.message = "User is already registered.";
			}
			this.loading = false;
		},
		submitVerificationCode() {
			this.loading = true;
			axios
				.post(REMOTE_SERVER_1 + "user/signup/verify", {
					activationCode: this.verificationCode,
					phoneNumber: this.user.countrycode + this.user.phoneNumber,
					name: this.user.fullName,
					password: this.user.password,
					isWeb: 1,
				})
				.then((r) => {
					if (r.data.data) {
						sessionStorage.setItem("_token", r.data.data.newToken);
						prepareHeaders(r.data.data.newToken, axios);
						this.$store.commit("SET_USER", r.data.data.user);
						localStorage.setItem(
							"user",
							JSON.stringify({
								name: r.data.data.user.name,
								imageURL: r.data.data.user.avatar.picture.nameOnServer,
							})
						);
						location.reload();
					}
					this.loading = false;
					this.message = "Could not sign you up.";
				});
		},
	},
};
</script>

<style scoped>
.error {
	text-align: center;
	color: red;
}
</style>
